import React, { useState } from 'react';
import Navbar from './Navbar';
import ReportContainer from './ReportContainer';
import styles from './Dashboard.module.css'; // Ensure this CSS file exists in the same folder as your component

const Dashboard = ({ onLogout }) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [expandedSection, setExpandedSection] = useState(null);

  const handleReportSelect = (reportId) => {
    setSelectedReport(reportId);
  };

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const dashboards = [
    { name: "PRT Usage", id: "prtUsage" },
    { name: "Dashboard Usage", id: "dashboardUsage" },
    { name: "GENAIE Usage", id: "genaieUsage" },
    { name: "Sprint Analysis", id: "sprintAnalysis" },
    { name: "TPS Sentiment Analysis", id: "tpsSentiment" }
  ];

  const spreadsheets = [
    { name: "Active Users", id: "activeUsers" },
    { name: "VC In Cell Usage", id: "vcInCellUsage" }
  ];

  const tools = [
    { name: "Bid Builder", id: "bidBuilder" },
    { name: "Document Translator", id: "documentTranslator" }
  ];

  return (
    <div className={styles.dashboard}>
      <Navbar onLogout={onLogout} onReportSelect={handleReportSelect} />
      {!selectedReport && (
        <div className={styles.welcomeMessage}>
          Welcome to the Data & Machine Learning Portal
        </div>
      )}
      {!selectedReport && (
        <div className={styles.sections}>
          <div className={styles.section}>
            <div className={styles.sectionHeader} onClick={() => toggleSection('dashboards')}>
              Dashboards
            </div>
            {expandedSection === 'dashboards' && (
              <div className={styles.sectionContent}>
                {dashboards.map(dashboard => (
                  <a href="#" key={dashboard.id} onClick={() => handleReportSelect(dashboard.id)} className={styles.link}>
                    {dashboard.name}
                  </a>
                ))}
              </div>
            )}
          </div>
          <div className={styles.section}>
            <div className={styles.sectionHeader} onClick={() => toggleSection('spreadsheets')}>
              Spreadsheets
            </div>
            {expandedSection === 'spreadsheets' && (
              <div className={styles.sectionContent}>
                {spreadsheets.map(spreadsheet => (
                  <a href="#" key={spreadsheet.id} onClick={() => handleReportSelect(spreadsheet.id)} className={styles.link}>
                    {spreadsheet.name}
                  </a>
                ))}
              </div>
            )}
          </div>
          <div className={styles.section}>
            <div className={styles.sectionHeader} onClick={() => toggleSection('tools')}>
              Tools
            </div>
            {expandedSection === 'tools' && (
              <div className={styles.sectionContent}>
                {tools.map(tool => (
                  <a href="#" key={tool.id} onClick={() => handleReportSelect(tool.id)} className={styles.link}>
                    {tool.name}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {selectedReport && <ReportContainer reportName={selectedReport} />}
    </div>
  );
};

export default Dashboard;
