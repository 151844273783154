// src/components/Navbar.js
import React from 'react';
import styles from './Navbar.module.css'; // Make sure this import path is correct

const Navbar = ({ onLogout, onReportSelect }) => {
  const dashboards = [
    { name: "PRT Usage", id: "prtUsage" },
    { name: "Dashboard Usage", id: "dashboardUsage" },
    { name: "GENAIE Usage", id: "genaieUsage" },
    { name: "Sprint Analysis", id: "sprintAnalysis" },
    { name: "TPS Sentiment Analysis", id: "tpsSentiment" }
  ];

  const spreadsheets = [
    { name: "Active Users", id: "activeUsers" },
    { name: "VC In Cell Usage", id: "vcInCellUsage" }
  ];

  const tools = [
    { name: "Bid Builder", id: "bidBuilder" },
    { name: "Document Translator", id: "documentTranslator" }
  ];

  return (
    <nav className={styles.navbar}>
      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Dashboards</button>
        <div className={styles.dropdownContent}>
          {dashboards.map(dashboard => (
            <a href="#" key={dashboard.id} onClick={() => onReportSelect(dashboard.id)}>
              {dashboard.name}
            </a>
          ))}
        </div>
      </div>

      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Spreadsheets</button>
        <div className={styles.dropdownContent}>
          {spreadsheets.map(spreadsheet => (
            <a href="#" key={spreadsheet.id}>
              {spreadsheet.name}
            </a>
          ))}
        </div>
      </div>

      <div className={styles.dropdown}>
        <button className={styles.dropbtn}>Tools</button>
        <div className={styles.dropdownContent}>
          {tools.map(tool => (
            <a href="#" key={tool.id}>
              {tool.name}
            </a>
          ))}
        </div>
      </div>

      <button onClick={onLogout} className={styles.logoutButton}>
        Logout
      </button>
    </nav>
  );
};

export default Navbar;