import React, { useState } from 'react';
import styles from './Login.module.css'; // Assuming you have Login.module.css

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = (event) => {
    event.preventDefault();

    fetch('https://dml-functions.azurewebsites.net/api/DML-Portal-login?code=UsDc1ooCI3dqy0gL_Fom8XLErHEGT4qNfJTgRa-d1HlXAzFuImsI-Q==', {
        method: 'POST',
        body: JSON.stringify({username, password}),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (response.status === 401) {
          alert('Login failed!');
          return;
        } 
        return response.json();
      }).then(data => {
        if (data && data.success) {
          onLoginSuccess(data.token, data.reports);
        }
      }).catch(error => {
        console.error('Error during login:', error);
      });
    };

    return (
        <div className={styles.loginContainer}>
          <form onSubmit={handleLogin} className={styles.formSignin}>
            <h2 className={styles.formSigninHeading}>Login</h2>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className={styles.formControl}
              placeholder="Username"
              required
              autoFocus
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.formControl}
              placeholder="Password"
              required
            />
            <button className={styles.loginButton} type="submit">
              Login
            </button>
          </form>
        </div>
      );
    };
    
    export default Login;